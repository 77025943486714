import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  writeBatch,
  doc,
  collection,
  query,
  where,
  getDocs,
  increment,
  updateDoc,
} from "firebase/firestore";
import _ from "lodash";
import { db } from "../../firebase";
import { findOrCreateGame } from "./recordSlice";
import {
  UserBetStatByShoe,
  UserBetTransaction,
  SaveShoeResultPayload,
} from "../../types";
import Logger from "../../features/global/Logger";
interface BettingTableState {
  loading: boolean;
  currentRound: "first" | "knockout" | "semi-final" | "final" | null;
  currentGroupId: string | null;
  currentShoeId: string | null;
  selectedPlayers: (string | null)[];
  isPlayerModalVisible: boolean;
  isBetModalVisible: boolean;
  betAmount: number;
  currentPosition: number | null;
  addingBetPlayer: string | null;
  addingBetPosition: string | null;
  toggleMinus: 1 | -1;
  playerBets: { [key: string]: { [key: string]: number } };
  sumPositionBets: { [key: string]: number };
  sumPlayerBets: { [key: string]: number };
  winningPositions: { [key: string]: boolean };
  playerResults: { [key: string]: { [key: string]: number } };
  sumPlayerResults: { [key: string]: number };
  sumPositionResults: { [key: string]: number };
  playerFirstBetWin: { [key: string]: boolean };
  playerBetStats: { [key: string]: UserBetStatByShoe[] };
  playerBetStat: { [key: string]: UserBetStatByShoe };
  isEndShoeModalVisible: boolean;
  allPlayerBetStats: UserBetStatByShoe[];
  isVisibleChangePlayerBalanceModal: boolean;
  selectedPlayerBalance: number;
}

const initialState: BettingTableState = {
  loading: false,
  currentRound: null,
  currentGroupId: null,
  currentShoeId: null,
  selectedPlayers: [null, null, null, null, null],
  isPlayerModalVisible: false,
  isBetModalVisible: false,
  betAmount: 0,
  currentPosition: null,
  addingBetPlayer: null,
  addingBetPosition: null,
  toggleMinus: 1,
  playerBets: {},
  sumPositionBets: {},
  sumPlayerBets: {},
  winningPositions: {},
  playerResults: {},
  sumPlayerResults: {},
  sumPositionResults: {},
  playerFirstBetWin: {},
  playerBetStats: {},
  playerBetStat: {},
  isEndShoeModalVisible: false,
  allPlayerBetStats: [],
  isVisibleChangePlayerBalanceModal: false,
  selectedPlayerBalance: 0,
};

export const getInitBalacnce = (
  round: "first" | "knockout" | "semi-final" | "final"
) => {
  switch (round) {
    case "first":
      return 100_000;
    case "knockout":
      return 100_000;
    case "semi-final":
      return 100_000;
    case "final":
      return 250_000;
    default:
      return 0;
  }
};

// Async Thunk update total balance of player by shoeId
export const updateTotalBalance = createAsyncThunk(
  "bettingTable/updateTotalBalance",
  async (
    {
      playerId,
      shoeId,
      totalBalance,
    }: { playerId: string; shoeId: string; totalBalance: number },
    { rejectWithValue }
  ) => {
    try {
      const userBetStatsQuery = query(
        collection(db, "userShoeBetStats"),
        where("playerId", "==", playerId),
        where("shoeId", "==", shoeId)
      );

      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      let userBetStatxx: UserBetStatByShoe | undefined;
      userBetStatsSnapshot.forEach((doc) => {
        userBetStatxx = { id: doc.id, ...doc.data() } as UserBetStatByShoe;
      });

      if (userBetStatxx && userBetStatxx.id) {
        const userBetStatRef = doc(db, "userShoeBetStats", userBetStatxx.id);
        if (userBetStatxx.totalBalance <= 0 && totalBalance > 0) {
          await updateDoc(userBetStatRef, {
            totalBalance,
            status: "running",
          });
        } else {
          await updateDoc(userBetStatRef, { totalBalance });
        }
      }
    } catch (error) {
      return rejectWithValue("Failed to update total balance");
    }
  }
);

// Async Thunk get all user bet stat
export const fetchUserBetStat = createAsyncThunk(
  "bettingTable/fetchUserBetStat",
  async (_, { rejectWithValue }) => {
    try {
      const userBetStatsQuery = query(collection(db, "userShoeBetStats"));
      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      return userBetStats;
    } catch (error) {
      Logger.error("Failed to fetch user bet stats", error);
      return rejectWithValue("Failed to fetch user bet stats");
    }
  }
);

// Async Thunk get all user bet stat sum by player
export const fetchUserBetStatSumByPlayer = createAsyncThunk(
  "bettingTable/fetchUserBetStatSumByPlayer",
  async (_, { rejectWithValue }) => {
    try {
      const userBetStatsQuery = query(collection(db, "userShoeBetStats"));
      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      const playerBetStats: { [key: string]: UserBetStatByShoe } = {};
      userBetStats.forEach((stat) => {
        if (!playerBetStats[stat.playerId]) {
          playerBetStats[stat.playerId] = {
            ...stat,
          };
        } else {
          playerBetStats[stat.playerId].totalBet += stat.totalBet;
          playerBetStats[stat.playerId].totalBetAmount += stat.totalBetAmount;
          playerBetStats[stat.playerId].totalBetResultAmount +=
            stat.totalBetResultAmount;
          playerBetStats[stat.playerId].totalBalance += stat.totalBalance;
          playerBetStats[stat.playerId].maxWinAmount = Math.max(
            playerBetStats[stat.playerId].maxWinAmount,
            stat.maxWinAmount
          );

          playerBetStats[stat.playerId].maxWinStackTimes = Math.max(
            playerBetStats[stat.playerId].maxWinStackTimes,
            stat.maxWinStackTimes
          );
          playerBetStats[stat.playerId].currentGameWinAmount +=
            stat.currentGameWinAmount;

          playerBetStats[stat.playerId].firstBetWin += stat.firstBetWin;
          playerBetStats[stat.playerId].point += stat.point;
          playerBetStats[stat.playerId].lastGameWinAmount = Math.max(
            playerBetStats[stat.playerId].lastGameWinAmount,
            stat.lastGameWinAmount
          );
        }
      });

      return playerBetStats;
    } catch (error) {
      return rejectWithValue("Failed to fetch user bet stats");
    }
  }
);

// Async Thunk get user bet stat by round
export const fetchUserBetStatByRound = createAsyncThunk(
  "bettingTable/fetchUserBetStatByRound",
  async (round: string, { rejectWithValue }) => {
    try {
      const userBetStatsQuery = query(
        collection(db, "userShoeBetStats"),
        where("round", "==", round)
      );

      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      return userBetStats;
    } catch (error) {
      return rejectWithValue("Failed to fetch user bet stats");
    }
  }
);

//Async Thunk get user bet stat by group
export const fetchUserBetStatByGroup = createAsyncThunk(
  "bettingTable/fetchUserBetStatByGroup",
  async ({ groupId }: { groupId: string }, { rejectWithValue }) => {
    try {
      const userBetStatsQuery = query(
        collection(db, "userShoeBetStats"),
        where("groupId", "==", groupId)
      );

      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      return userBetStats;
    } catch (error) {
      return rejectWithValue("Failed to fetch user bet stats");
    }
  }
);

// Async Thunk get user bet stat by shoe
export const fetchUserBetStatByShoe = createAsyncThunk(
  "bettingTable/fetchUserBetStatByShoe",
  async (
    {
      groupId,
      shoeId,
    }: {
      groupId: string;
      shoeId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const userBetStatsQuery = query(
        collection(db, "userShoeBetStats"),
        where("groupId", "==", groupId),
        where("shoeId", "==", shoeId)
      );

      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      return userBetStats;
    } catch (error) {
      return rejectWithValue("Failed to fetch user bet stats");
    }
  }
);

// Async Thunk for saving a bet end game result
export const saveBetResult = createAsyncThunk(
  "bettingTable/saveBetResult",
  async (
    {
      round,
      groupId,
      groupName,
      shoeId,
      shoeNumber,
      gameNumber,
      gameId,
      reporter = undefined,
    }: {
      round: "first" | "knockout" | "semi-final" | "final";
      groupId: string;
      groupName: string;
      shoeId: string;
      shoeNumber: number;
      gameNumber: number;
      gameId: string;
      reporter?: string;
    },
    { dispatch, getState }
  ) => {
    try {
      const state = getState() as { bettingTable: BettingTableState };
      const playerResults = state.bettingTable.playerResults;
      const sumPlayerResults = state.bettingTable.sumPlayerResults;
      const playerFirstBetWin = state.bettingTable.playerFirstBetWin;
      const sumPlayerBets = state.bettingTable.sumPlayerBets;
      const winningPositions = state.bettingTable.winningPositions;
      const playerBetStats = state.bettingTable.playerBetStats;

      // find player totalBalance is zero before this game
      const playerBetStatsZeroBalanceBefore = Object.keys(
        playerBetStats
      ).filter(
        (playerId) =>
          playerBetStats[playerId].length > 0 &&
          playerBetStats[playerId][0].totalBalance <= 0
      );

      const playerLength = Object.keys(playerBetStats).length;
      // find player totalBalance is zero in this game
      const playerBetStatsZeroBalanceNow = Object.keys(sumPlayerResults).filter(
        (playerId) => {
          if (!playerBetStats[playerId]) {
            return getInitBalacnce(round) + sumPlayerResults[playerId] <= 0;
          }

          return (
            playerBetStats[playerId].length > 0 &&
            playerBetStats[playerId][0].totalBalance +
              sumPlayerResults[playerId] <=
              0
          );
        }
      );

      const batch = writeBatch(db);
      const gameRef = doc(db, "games", `${shoeId}-${gameNumber}`);
      batch.update(gameRef, {
        winningPositions,
        status: "ended",
        endedAt: Date.now(),
        reporter: reporter,
      });

      // Get userBetStats
      const userBetStatsQuery = query(
        collection(db, "userShoeBetStats"),
        where("groupId", "==", groupId),
        where("shoeId", "==", shoeId),
        where("round", "==", round)
      );

      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      // Update userBetStats and userBetTransactions
      for (const [playerId, results] of Object.entries(playerResults)) {
        for (const [position, amount] of Object.entries(results)) {
          const userBetTransaction: UserBetTransaction = {
            round,
            playerId,
            groupId,
            groupName,
            shoeId,
            shoeNumber,
            gameId,
            gameNumber,
            betType: position as
              | "PLAYER"
              | "BANKER"
              | "TIE"
              | "B-PAIR"
              | "P-PAIR",
            betAmount: sumPlayerBets[playerId],
            betResultAmount: amount,
            createdAt: Date.now(),
          };

          const userBetTransactionRef = collection(db, "userBetTransactions");
          batch.set(doc(userBetTransactionRef), userBetTransaction);
        }

        // Update userBetStat
        let userBetStat = userBetStats.find(
          (stat) => stat.playerId === playerId
        );
        const gameResultStr =
          sumPlayerResults[playerId] > 0
            ? "win"
            : sumPlayerResults[playerId] < 0
            ? "lose"
            : "draw";

        if (userBetStat) {
          const userBetStatRef = doc(
            db,
            "userShoeBetStats",
            userBetStat.id as string
          );
          const updateData: any = {
            totalBet: increment(1),
            totalBetAmount: increment(sumPlayerBets[playerId]),
            totalBetResultAmount: increment(sumPlayerResults[playerId]),
            maxWinAmount: Math.max(
              userBetStat.maxWinAmount,
              sumPlayerResults[playerId] > 0 ? sumPlayerResults[playerId] : 0
            ),
            lastGameResult: gameResultStr,
            currentWinStackTimes:
              gameResultStr === "win"
                ? userBetStat.currentWinStackTimes + 1
                : 0,
            maxWinStackTimes: Math.max(
              userBetStat.maxWinStackTimes,
              gameResultStr === "win" ? userBetStat.currentWinStackTimes + 1 : 0
            ),
            currentGameWinAmount: sumPlayerResults[playerId],
            currentGameNumber: gameNumber,
            totalBalance: increment(sumPlayerResults[playerId]),
            reporter: reporter,
          };

          debugger;
          if (playerBetStatsZeroBalanceNow.includes(playerId)) {
            if (playerLength - playerBetStatsZeroBalanceBefore.length <= 3) {
              updateData["status"] = "ended";
              updateData["point"] = 4;
            } else {
              updateData["status"] = "ended";
              updateData["point"] = 0;
            }
          }

          batch.update(userBetStatRef, updateData);
        } else {
          userBetStat = {
            round,
            playerId,
            groupId,
            groupName,
            shoeId,
            shoeNumber,
            totalBet: 1,
            totalBetAmount: sumPlayerBets[playerId],
            totalBalance: getInitBalacnce(round) + sumPlayerResults[playerId],
            maxWinAmount:
              sumPlayerResults[playerId] > 0 ? sumPlayerResults[playerId] : 0,
            lastGameResult: gameResultStr,
            currentWinStackTimes: gameResultStr === "win" ? 1 : 0,
            maxWinStackTimes: gameResultStr === "win" ? 1 : 0,
            currentGameWinAmount: sumPlayerResults[playerId],
            lastGameWinAmount: 0,
            firstBetWin: playerFirstBetWin[playerId] ? 1 : 0,
            totalBetResultAmount: sumPlayerResults[playerId],
            currentGameNumber: gameNumber,
            point: 0,
            createdAt: Date.now(),
            status: "running",
            reporter: reporter,
          };
          if (playerBetStatsZeroBalanceNow.includes(playerId)) {
            if (playerLength - playerBetStatsZeroBalanceBefore.length <= 3) {
              userBetStat["status"] = "ended";
              userBetStat["point"] = 4;
            } else {
              userBetStat["status"] = "ended";
              userBetStat["point"] = 0;
            }
          }

          const userBetStatRef = collection(db, "userShoeBetStats");
          batch.set(doc(userBetStatRef), userBetStat);
        }
      }
      await batch.commit();
      await dispatch(findOrCreateGame({ shoeId, groupId, shoeNumber }));
    } catch (error) {
      Logger.error("Error saving bet result:", error);
      return error;
    }
  }
);

// Async Thunk for saving a bet end Shoe result

export const saveShoeResult = createAsyncThunk(
  "bettingTable/saveShoeResult",
  async ({
    round,
    groupId,
    shoeId,
    gameId,
    gameNumber,
    data,
  }: SaveShoeResultPayload) => {
    try {
      const batch = writeBatch(db);
      const shoeRef = doc(db, "shoes", shoeId);
      batch.update(shoeRef, {
        status: "ended",
        endedAt: Date.now(),
      });

      // Get userBetStats
      const userBetStatsQuery = query(
        collection(db, "userShoeBetStats"),
        where("groupId", "==", groupId),
        where("shoeId", "==", shoeId)
      );

      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      // sort by totalBalacne
      userBetStats.sort((a, b) => b.totalBalance - a.totalBalance);

      // Calculate point by totalBalance for 1st give 7 points, 2nd give 4 points, 3rd give 4 points
      userBetStats.forEach((stat, index) => {
        if (stat.status === "ended") return;

        const userBetStatRef = doc(db, "userShoeBetStats", stat.id as string);
        let points = 0;
        if (index === 0) points = 7;
        else if (index === 1) points = 4;
        else if (index === 2) points = 4;

        if (stat.totalBalance <= 0) points = 0;

        const pointData = data.find((d) => d.id === stat.playerId);
        let remark = "";
        if (pointData) {
          points = pointData.point;
          remark = pointData.remark;
        }

        const updateData: any = {
          point: points,
          status: "ended",
        };
        if (remark) {
          updateData["remark"] = remark;
        }

        if (
          stat.currentGameWinAmount > 0 &&
          stat.currentGameNumber >= gameNumber - 1
        ) {
          updateData["lastGameWinAmount"] = stat.currentGameWinAmount;
        }
        batch.update(userBetStatRef, updateData);
      });

      await batch.commit();
    } catch (error) {
      Logger.error("Error saving shoe result:", error);
      return error;
    }
  }
);

const bettingTableSlice = createSlice({
  name: "bettingTable",
  initialState,
  reducers: {
    initState(
      state,
      action: PayloadAction<{ group: string; round: string; shoeId: string }>
    ) {
      const { group, round, shoeId } = action.payload;
      state.currentGroupId = group;
      state.currentRound = round as
        | "first"
        | "knockout"
        | "semi-final"
        | "final";
      state.currentShoeId = shoeId;
      state.selectedPlayers = JSON.parse(
        localStorage.getItem(`selectedplayer-${state.currentGroupId}`)!
      ) || [null, null, null, null, null];
    },
    setPlayerModalVisible(state, action: PayloadAction<boolean>) {
      state.isPlayerModalVisible = action.payload;
    },
    setBetAmount(state, action: PayloadAction<number>) {
      const value = action.payload;
      if (state.addingBetPlayer !== null && value >= 0) {
        const playerBetStats = state.playerBetStats[state.addingBetPlayer];
        if (playerBetStats) {
          if (
            playerBetStats.length > 0 &&
            playerBetStats[0].totalBalance > 0 &&
            playerBetStats[0].totalBalance >= value
          ) {
            state.betAmount = value;
          }
        } else {
          if (getInitBalacnce(state.currentRound as any) >= value) {
            state.betAmount = value;
          }
        }
      }
    },
    setToggleMinus(state, action: PayloadAction<1 | -1>) {
      state.toggleMinus = action.payload;
    },
    openPlayerModal(state, action: PayloadAction<number | null>) {
      state.isPlayerModalVisible = true;
      state.currentPosition = action.payload;
    },
    selectPlayerToPosition(state, action: PayloadAction<string>) {
      if (state.currentPosition !== null) {
        const updatedPlayers = [...state.selectedPlayers];
        updatedPlayers[state.currentPosition] = action.payload;
        state.selectedPlayers = updatedPlayers;
        state.isPlayerModalVisible = false;

        // Sync with localStorage
        localStorage.setItem(
          `selectedplayer-${state.currentGroupId}`,
          JSON.stringify(updatedPlayers)
        );
      }
    },
    removePlayerFromPosition(state, action: PayloadAction<number>) {
      const updatedPlayers = [...state.selectedPlayers];
      updatedPlayers[action.payload] = null;
      state.selectedPlayers = updatedPlayers;
      // Sync with localStorage
      localStorage.setItem(
        `selectedplayer-${state.currentGroupId}`,
        JSON.stringify(updatedPlayers)
      );
    },
    openChangePlayerBalanceModal(
      state,
      action: PayloadAction<{ player: string }>
    ) {
      if (state.playerBetStats[action.payload.player]) {
        state.isVisibleChangePlayerBalanceModal = true;
        state.selectedPlayerBalance =
          state.playerBetStats[action.payload.player][0].totalBalance;
        state.addingBetPlayer = action.payload.player;
      }
    },
    canclePlayerBalanceModal(state) {
      state.isVisibleChangePlayerBalanceModal = false;
      state.selectedPlayerBalance = 0;
      state.addingBetPlayer = null;
    },
    openBet(
      state,
      action: PayloadAction<{ player: string; position: string }>
    ) {
      // verify current player balance before open bet modal
      const playerBetStats = state.playerBetStats[action.payload.player];
      if (playerBetStats) {
        if (playerBetStats.length > 0 && playerBetStats[0].totalBalance <= 0) {
          return;
        }
      } else {
        if (getInitBalacnce(state.currentRound as any) <= 0) {
          return;
        }
      }

      if (!state.playerBets[action.payload.player]) {
        state.playerBets[action.payload.player] = {};
      }

      state.betAmount =
        state.playerBets[action.payload.player]?.[action.payload.position] || 0;
      state.addingBetPlayer = action.payload.player;
      state.addingBetPosition = action.payload.position;
      state.isBetModalVisible = true;
    },
    cancelModalBet(state) {
      state.isBetModalVisible = false;
      state.addingBetPlayer = null;
      state.addingBetPosition = null;
    },
    saveBet(state, action: PayloadAction<number>) {
      const betAmount = action.payload;
      if (state.addingBetPlayer && state.addingBetPosition) {
        state.playerBets[state.addingBetPlayer] = {
          ...state.playerBets[state.addingBetPlayer],
          [state.addingBetPosition]: betAmount,
        };

        state.sumPositionBets[state.addingBetPosition] = Object.values(
          state.playerBets
        )
          .map((bets) => bets[state.addingBetPosition as string] || 0)
          .reduce((a, b) => a + b, 0);

        state.sumPlayerBets[state.addingBetPlayer] = Object.values(
          state.playerBets[state.addingBetPlayer]
        ).reduce((a, b) => a + b, 0);

        state.betAmount = 0;
        state.addingBetPlayer = null;
        state.addingBetPosition = null;
        state.isBetModalVisible = false;
      }
    },
    setWinningPosition(
      state,
      action: PayloadAction<{ position: string; value: boolean }>
    ) {
      state.winningPositions[action.payload.position] = action.payload.value;
    },
    toggleWinningPosition(state, action: PayloadAction<string>) {
      state.winningPositions[action.payload] =
        !state.winningPositions[action.payload];
    },
    clearWinningPositions(state) {
      state.winningPositions = {};
    },
    calculateBetResults(state) {
      const results: { [key: string]: { [key: string]: number } } = {};
      // Loop through each selected player to calculate their results
      state.selectedPlayers.forEach((playerId) => {
        if (playerId && state.playerBets[playerId]) {
          // Loop through each position bet by the player
          for (const [position, betAmount] of Object.entries(
            state.playerBets[playerId]
          )) {
            let total = 0;
            // If the position is winning, add the bet amount to the total; otherwise, subtract it
            if (state.winningPositions[position]) {
              let winAmount = 0;
              switch (position) {
                case "PLAYER":
                  winAmount = betAmount * 1;
                  break;
                case "BANKER":
                  winAmount = betAmount * 1;
                  break;
                case "TIE":
                  winAmount = betAmount * 8;
                  break;
                case "B-PAIR":
                  winAmount = betAmount * 11;
                  break;
                case "P-PAIR":
                  winAmount = betAmount * 11;
                  break;
              }
              total += winAmount;
            } else if (
              state.winningPositions["TIE"] &&
              (position === "PLAYER" || position === "BANKER")
            ) {
              total = 0;
            } else {
              total -= betAmount;
            }
            // Add the total to the player's results
            if (!results[playerId]) {
              results[playerId] = {};
            }
            results[playerId][position] = total;
          }
        }
      });

      state.playerResults = _.cloneDeep(results);
      const results2 = _.cloneDeep(results);

      state.sumPositionResults = Object.values(results).reduce(
        (acc, result) => {
          for (const [position, amount] of Object.entries(result)) {
            if (!acc[position]) {
              acc[position] = 0;
            }
            acc[position] += amount;
          }
          return acc;
        },
        {}
      );

      state.sumPlayerResults = Object.entries(results2).reduce(
        (acc: { [key: string]: number }, [playerId, result]) => {
          acc[playerId] = Object.values(result).reduce(
            (sum, amount) => sum + amount,
            0
          );
          return acc;
        },
        {}
      );
    },
    clearState(state) {
      state.selectedPlayers = [null, null, null, null, null];
      state.isPlayerModalVisible = false;
      state.isBetModalVisible = false;
      state.betAmount = 0;
      state.currentPosition = null;
      state.addingBetPlayer = null;
      state.addingBetPosition = null;
      state.toggleMinus = 1;
      state.playerBets = {};
      state.sumPositionBets = {};
      state.sumPlayerBets = {};
      state.winningPositions = {};
      state.playerResults = {};
      state.sumPlayerResults = {};
      state.sumPositionResults = {};
      state.playerFirstBetWin = {};
      state.playerBetStat = {};
      state.allPlayerBetStats = [];
      state.isEndShoeModalVisible = false;
    },
    togglePlayerFirstBetWin(state, action: PayloadAction<string>) {
      state.playerFirstBetWin[action.payload] =
        !state.playerFirstBetWin[action.payload];
    },
    openEndShoeModal(state) {
      state.isEndShoeModalVisible = true;
    },
    closeEndShoeModal(state) {
      state.isEndShoeModalVisible = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveBetResult.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(saveBetResult.fulfilled, (state) => {
      state.isPlayerModalVisible = false;
      state.isBetModalVisible = false;
      state.betAmount = 0;
      state.currentPosition = null;
      state.addingBetPlayer = null;
      state.addingBetPosition = null;
      state.toggleMinus = 1;
      state.playerBets = {};
      state.sumPositionBets = {};
      state.sumPlayerBets = {};
      state.winningPositions = {};
      state.playerResults = {};
      state.sumPlayerResults = {};
      state.sumPositionResults = {};
      state.playerFirstBetWin = {};
      state.loading = false;
    });

    builder.addCase(saveBetResult.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchUserBetStatByShoe.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserBetStatByShoe.fulfilled, (state, action) => {
      const userBetStats = action.payload;
      const playerBetStats: { [key: string]: UserBetStatByShoe[] } = {};

      userBetStats.forEach((stat) => {
        if (!playerBetStats[stat.playerId]) {
          playerBetStats[stat.playerId] = [];
        }
        playerBetStats[stat.playerId].push(stat);
      });

      state.playerBetStats = playerBetStats;
    });
    builder.addCase(fetchUserBetStatByShoe.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchUserBetStatByGroup.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchUserBetStatByGroup.fulfilled, (state, action) => {
      const userBetStats = action.payload;
      const playerBetStats: { [key: string]: UserBetStatByShoe[] } = {};

      userBetStats.forEach((stat) => {
        if (!playerBetStats[stat.playerId]) {
          playerBetStats[stat.playerId] = [];
        }
        playerBetStats[stat.playerId].push(stat);
      });

      state.playerBetStats = playerBetStats;
      state.loading = false;
    });

    builder.addCase(fetchUserBetStatByGroup.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchUserBetStatByRound.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchUserBetStatByRound.fulfilled, (state, action) => {
      const userBetStats = action.payload;
      const playerBetStats: { [key: string]: UserBetStatByShoe[] } = {};

      userBetStats.forEach((stat) => {
        if (!playerBetStats[stat.playerId]) {
          playerBetStats[stat.playerId] = [];
        }
        playerBetStats[stat.playerId].push(stat);
      });
      state.playerBetStats = playerBetStats;
      state.loading = false;
    });

    builder.addCase(fetchUserBetStatByRound.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchUserBetStatSumByPlayer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserBetStatSumByPlayer.fulfilled, (state, action) => {
      state.playerBetStat = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchUserBetStatSumByPlayer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchUserBetStat.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserBetStat.fulfilled, (state, action) => {
      state.allPlayerBetStats = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchUserBetStat.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(saveShoeResult.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveShoeResult.fulfilled, (state) => {
      state.isEndShoeModalVisible = false;
      // Move selectedPlayers position 5 to 1, 1 to 2, 2 to 3, 3 to 4, 4 to 5
      const updatedPlayers = [...state.selectedPlayers];
      const lastPlayer = updatedPlayers.pop();
      updatedPlayers.unshift(lastPlayer || null);
      state.selectedPlayers = updatedPlayers;
      localStorage.setItem(
        `selectedplayer-${state.currentGroupId}`,
        JSON.stringify(updatedPlayers)
      );
      state.loading = false;
      //
    });
    builder.addCase(saveShoeResult.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTotalBalance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTotalBalance.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTotalBalance.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setPlayerModalVisible,
  setBetAmount,
  setToggleMinus,
  openPlayerModal,
  selectPlayerToPosition,
  removePlayerFromPosition,
  openBet,
  saveBet,
  cancelModalBet,
  setWinningPosition,
  toggleWinningPosition,
  clearWinningPositions,
  calculateBetResults,
  clearState,
  togglePlayerFirstBetWin,
  initState,
  openEndShoeModal,
  closeEndShoeModal,
  openChangePlayerBalanceModal,
  canclePlayerBalanceModal,
} = bettingTableSlice.actions;

export default bettingTableSlice.reducer;
